/*
 *  Breakpoints for Responsive Design
 *  TODO : - have this feed into the mixins file for defining breakpoints
 *  TODO : - use REM sizing
 */
/*
 *  Base Colors
 */
/*
 *  Background Colors
 */
@font-face {
  font-family: "Proxima Nova";
  font-weight: 300;
  font-style: normal;
  src: url("/fonts/ProximaNova-Light.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 300;
  font-style: italic;
  src: url("/fonts/ProximaNova-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  font-style: normal;
  src: url("/fonts/ProximaNova-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  font-style: italic;
  src: url("/fonts/ProximaNova-RegularItalic.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  font-style: normal;
  src: url("/fonts/ProximaNova-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  font-style: italic;
  src: url("/fonts/ProximaNova-SemiboldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 600;
  font-style: normal;
  src: url("/fonts/ProximaNova-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 600;
  font-style: italic;
  src: url("/fonts/ProximaNova-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-style: normal;
  src: url("/fonts/ProximaNova-Extrabold.otf") format("opentype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 800;
  font-style: normal;
  src: url("/fonts/ProximaNova-Black.otf") format("opentype");
}
/*
 *  Base Animation Variables
 */
/*
 *  NProgress Bar Colors
 */
/*
 *  User Select / Text Highlight
 */
/*
 *  Colors for other variables
 */
/*
 *  Styling for HTML and Body elements
 */
/*
 *  Animations
 *
 *  Used for a variety of transitions.
 */
/*
 *  Fonts
 *
 *  ...
 */
/*
 *  Pullouts
 *
 *  Used for timeline calendar, breakdown, and filters.
 */
/*
 *  Calendar
 *
 *  Used for calendar pullout.
 */
/*
 *  NPProgress
 *
 *  Used for the NPProgress Bar.
 */
/*
 *  Toolbar / ProfileSelector
 *
 *  Used for event toolbar and the profile selector.
 */
/*
 *  Colors
 *
 *  Used to loop through when needed for statuses
 */
/*
 *  Toolbar / ProfileSelector
 *
 *  Used for event toolbar and the profile selector.
 */
/*
 *  Breakpoints for Responsive Design
 *  TODO : - have this feed into the mixins file for defining breakpoints
 *  TODO : - use REM sizing
 */
/** Usage:
 *  @include breakpoint(tablet) {}
 *  @include breakpoint(min-width, 1182px) {}
 *  @include between-breakpoints(0, 1020px) {}
 */
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr,
acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong,
sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
  max-width: 100%;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

ins {
  background: #ff0;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
}

del {
  text-decoration: line-through;
}

b, strong {
  font-weight: bold;
}

em, dfn {
  font-style: italic;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  height: 0;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
}

legend {
  white-space: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: "courier new", monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Improve readability of pre-formatted text in all browsers */
svg:not(:root) {
  overflow: visible;
}

/* Correct overflow not hidden in IE9 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* HTML5 Definitions */
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

/* Reset Form Styles */
button[disabled], input[disabled] {
  cursor: default;
}

button, input {
  line-height: normal;
}

/* Define line-height as normal to match FF3/4 */
select, input, textarea, button {
  font-size: 100%;
  vertical-align: baseline;
  vertical-align: middle;
  text-transform: none;
}

/* Font Normalization */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

button {
  width: auto;
  overflow: visible;
  border: #fff solid 0px;
}

/* Make buttons play nice in IE */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Remove inner padding and border in FF3/4 */
/* Consistent box sizing and appearance */
input[type=checkbox], input[type=radio] {
  padding: 0;
  width: 13px;
  height: 13px;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Remove inner padding and border in FF3/4: h5bp.com/l */
/* Align checkboxes, radios, text inputs with their label */
input[type=checkbox],
input[type=radio] {
  height: 13px;
  width: 13px;
  vertical-align: middle;
}

/* Hand cursor on clickable input elements */
label, input[type=button], input[type=submit], input[type=reset],
input[type=image], button {
  cursor: pointer;
  -webkit-appearance: none;
  overflow: visible;
}

/* Colors for form validity */
input:invalid, textarea:invalid {
  background-color: #f0dddd;
}

/* Highlight Styles */
::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

html {
  font-size: 100%;
  min-height: 100%;
  background-color: #c7d1db;
  overflow-y: scroll;
  height: 100%;
}

body {
  min-height: 100%;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: transparent;
  height: 100%;
}

.AppContainer {
  height: 100%;
}

body,
button,
input,
select,
textarea {
  color: #444;
  font-style: normal;
  font-family: "Proxima Nova", -apple-system, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-size: 1rem;
}

.h1 {
  font-size: 26px;
  font-size: 1.625rem;
}

.h2 {
  font-size: 23px;
  font-size: 1.4375rem;
  margin-bottom: 1rem;
}

.h3 {
  font-size: 20px;
  font-size: 1.25rem;
}

p {
  line-height: 130%;
  padding: 0.25rem 0;
  margin-bottom: 0.5rem;
}

hr {
  border: none;
  border-top: #fff solid 1px;
  margin: 2rem 0;
}

select, input, textarea {
  border: #c7d1db solid 1px;
  border-radius: 0;
  appearance: none;
  background: #fff;
  padding: 0.25rem 0.5rem;
  min-height: 2.5rem;
  width: 100%;
}
select:disabled, input:disabled, textarea:disabled {
  border-color: #f2f5f7;
}

input[type=radio],
input[type=checkbox] {
  min-height: auto;
  height: 1rem;
  width: 1rem;
  border-width: 2px;
}
input[type=radio]:checked,
input[type=checkbox]:checked {
  box-shadow: inset 0 0 0 2px white;
  border-color: #8aa5ea;
  background-color: #5568d8;
}
input[type=radio]:checked:disabled,
input[type=checkbox]:checked:disabled {
  border-color: #e6ebf0;
  background-color: #d9e0e8;
}

input[type=radio] {
  border-radius: 1rem;
}

select[multiple=multiple] option {
  word-wrap: normal !important;
  white-space: normal;
  padding: 0.5rem 0.25rem;
  border-top: #d9e0e8 dashed 1px;
}
select[multiple=multiple] option:first-child {
  border-top: none;
}

textarea {
  min-height: 8rem;
}

/*================================================
	@form @inputs @submit
=================================================*/
a {
  text-decoration: none;
}

.form-con {
  padding: 1.5rem 1rem 1rem;
}

.login-form {
  max-width: 300px;
  margin: 0 auto;
}

.login-form.login-oauth {
  max-width: 600px;
}

.login-form.challenge-form {
  max-width: 600px;
}

.login-form h1 {
  font-size: 140%;
  font-weight: 600;
}

.help-block {
  font-size: 14px;
  font-size: 0.875rem;
  padding-top: 0.5rem;
}

.login-form .form-header {
  border-bottom: #9ba7b4 solid 5px;
  padding: 0 0 10px;
}

.form-body {
  display: flex;
  flex-direction: column;
  background: #fff;
}
@media (min-width: 760px) {
  .form-body {
    flex-direction: row;
  }
}
.form-body .alert {
  color: #bf4042;
  font-weight: 500;
  padding: 0 0 0.75rem;
  line-height: 140%;
}

.form-login,
.oauth-con {
  padding: 1.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex-grow: 1;
}

.oauth-con {
  background: #f2f5f7;
}

.oauth-con h2 {
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-size: 1.125rem;
}

.oauth-con p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 140%;
}

.login-form p.placeholder {
  padding: 0 0 12px;
}

.login-form p.placeholder:first-child {
  padding: 0;
}

.login-form .placeholder label {
  position: absolute;
  z-index: 10;
  color: #333;
  font-weight: 800;
  top: 10px;
  left: 50px;
  font-size: 14px;
  font-size: 0.875rem;
  color: #9aa6b3;
  cursor: text;
}

.forgot-password {
  font-size: 14px;
  font-size: 0.875rem;
  text-align: right;
  display: inline-flex;
  padding: 0.75rem 1rem;
  background: #e6ebf0;
  border-radius: 0.25rem;
  margin: 0.5rem auto;
  color: #5568d8;
}
.forgot-password:hover {
  background: #262e36;
  color: #f2f5f7;
}

form p {
  position: relative;
}

form ul,
form ol {
  list-style: none;
  line-height: 120%;
  padding: 0;
}

/*form label,*/
form select {
  cursor: pointer;
}

form input[type=text],
form input[type=email],
form input[type=password],
form select,
form textarea {
  width: 100%;
  padding: 10px 18px;
  border: #fff solid 0px;
  box-sizing: border-box;
  background: #e9f0ed;
  background-repeat: no-repeat;
  border-radius: 3px;
}

form input.error,
form textarea.error,
form select.error {
  border: #e5394a solid 1px !important;
  background-color: #ffdad6 !important;
}

.form-small-text {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0 0 10px;
}

form label {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
}

form label em {
  color: #999;
  text-transform: none;
}

form label span.red {
  color: red;
}

form .form-checkbox label {
  display: inline-block;
}

form input[type=radio],
form input[type=checkbox],
form input[type=submit],
form #submit {
  cursor: pointer;
  width: auto;
}

form input[type=submit],
form input[type=button] form #submit {
  -webkit-appearance: none;
  background: #0095d9;
  width: 100%;
  border: #fff solid 0px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 14px 0;
}

form input[type=submit]:hover,
form #submit:hover {
  background-color: #4cb5e4;
}

form input[type=radio] {
  -webkit-appearance: radio;
}

form input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin-top: 0px;
}

form input:focus,
form select:focus,
textarea:focus {
  border-color: #0095da !important;
  background-color: #c9edfe !important;
  outline: none;
}

form input[type=submit]:focus,
form #submit:focus {
  background: #039ade;
}

form input[type=checkbox]:focus {
  outline: dashed;
  outline-color: #0095da;
}

/* Form Tips / Errors / Etc */
form .error input,
form .error select {
  background-color: #ffdad6 !important;
  border-color: #cc7f77 !important;
}

form .placeholder.error label {
  color: #cc7f77 !important;
}

.form-errors {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #dd4646;
}

.form-group {
  padding: 30px 30px 20px;
  margin-top: 14px;
  background: #fff;
  border-radius: 3px;
  border: #eee solid 1px;
}
.form-group .form-group {
  padding: 0 10px 0;
}

.form-group:first-child {
  border-top: none;
}

form.small-form label {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 120%;
}

label .req {
  color: red;
  font-weight: bold;
}

form.small-form .form-checkbox label {
  font-weight: normal;
}

form.small-form input[type=text],
form.small-form select,
form.small-form textarea,
form button {
  padding: 6px 10px;
  font-size: 14px;
  font-size: 0.875rem;
  border: #d6dbe1 solid 1px;
  background-color: #fff;
  border-radius: 3px;
}

form.small-form textarea {
  height: 120px;
}

.form-2,
.form-3,
.form-4,
.form-5,
.form-6 {
  clear: both;
}

.form-2 p,
.form-3 p,
.form-4 p,
.form-5 p,
.form-6 p {
  margin-left: 2.2222222222%;
  float: left;
}

.form-2 p:first-child,
.form-3 p:first-child,
.form-4 p:first-child,
.form-5 p:first-child,
.form-6 p:first-child {
  margin-left: 0;
}

.form-2 p {
  width: 48.8888888889%;
}

.form-3 p {
  width: 31.8518518519%;
}

.form-4 p {
  width: 23.3333333333%;
}

.form-5 p {
  width: 18.2222222222%;
}

.submit-form {
  padding: 20px 0 0;
}
.submit-form:first-child {
  padding-top: 0;
}

.submit-form:first-child {
  padding-top: 0;
}

form.small-form input[type=submit],
form.small-form .submit {
  cursor: pointer;
  width: auto;
}

form.small-form input[type=submit],
form.small-form .submit,
form button {
  -webkit-appearance: none;
  background: #0095d9;
  width: 100%;
  border: #fff solid 0px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 14px 0;
}

form.small-form input[type=submit]:hover,
form.small-form .submit:hover,
form button:hover {
  background-color: #4cb5e4;
}

form.small-form .form-button-small input[type=submit] {
  width: auto;
  text-transform: none;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 10px 18px;
  border-radius: 2px;
  float: right;
  margin: 0;
}

/*================================================
	@errors
=================================================*/
.error-con {
  background: #e9f0ed;
  padding: 0 0 20px;
}

.error-con p {
  color: #fff;
  background: #ed808b;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 5px 20px;
  border-radius: 3px;
  border: #e5394a solid 1px;
}

.btn-login {
  display: block;
  margin-bottom: 20px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eaeaea+0,dbdbdb+100 */
  background: #eaeaea;
  /* Old browsers */
  background: -moz-linear-gradient(top, #eaeaea 0%, #dbdbdb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #eaeaea 0%, #dbdbdb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eaeaea 0%, #dbdbdb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#eaeaea", endColorstr="#dbdbdb",GradientType=0 );
  /* IE6-9 */
  color: #654444;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 42px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
}

.btn-login i {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ea4f5c+0,ed1b2e+100 */
  background: #ea4f5c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ea4f5c 0%, #ed1b2e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ea4f5c 0%, #ed1b2e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ea4f5c 0%, #ed1b2e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ea4f5c", endColorstr="#ed1b2e",GradientType=0 );
  /* IE6-9 */
  height: 42px;
  line-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  border-bottom: #bb0909 solid 2px;
  border-left: #ED1B2E solid 1px;
  border-top: #ED1B2E solid 1px;
  /*border-top: #ea4f5c solid 1px;*/
  display: block;
  float: left;
  padding: 0 10px;
  width: 64px;
  margin-right: 16px;
  overflow: hidden;
  transition: all 0.15s;
  -moz-transition: all 0.15s;
  -webkit-transition: all 0.15s;
  -o-transition: all 0.15s;
}

.btn-login i img {
  max-width: 44px;
}

.btn-login i span {
  color: #fff;
  /*margin-left: 27px;*/
  position: absolute;
  left: 64px;
  top: 0;
  bottom: 0;
  display: block;
  border-left: #bb0909 solid 1px;
  padding-left: 14px;
}

.btn-login > span {
  display: block;
  width: 100%;
  height: 42px;
  line-height: 41px;
  padding-left: 80px;
  border: #ccc solid 1px;
  border-bottom-color: #b8b8b8;
  border-bottom-width: 2px;
  position: relative;
  font-weight: 500;
  z-index: 1;
}

.btn-login:hover i {
  width: 100%;
}

.btn-login:active i {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ed1b2e+0,ea4f5c+100 */
  background: #ed1b2e;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ed1b2e 0%, #ea4f5c 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ed1b2e 0%, #ea4f5c 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ed1b2e 0%, #ea4f5c 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ed1b2e", endColorstr="#ea4f5c",GradientType=0 );
  /* IE6-9 */
  border-bottom-width: 1px;
}

.btn-login:active i img {
  position: relative;
  top: 1px;
}

.btn-login:active i span {
  line-height: 42px;
}